import PropTypes from '../_util/vue-types';
function returnEmptyString() {
    return '';
}
function returnDocument(element) {
    if (element) {
        return element.ownerDocument;
    }
    return window.document;
}
export function noop() { }
export const triggerProps = () => ({
    action: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).def([]),
    showAction: PropTypes.any.def([]),
    hideAction: PropTypes.any.def([]),
    getPopupClassNameFromAlign: PropTypes.any.def(returnEmptyString),
    onPopupVisibleChange: Function,
    afterPopupVisibleChange: PropTypes.func.def(noop),
    popup: PropTypes.any,
    popupStyle: { type: Object, default: undefined },
    prefixCls: PropTypes.string.def('rc-trigger-popup'),
    popupClassName: PropTypes.string.def(''),
    popupPlacement: String,
    builtinPlacements: PropTypes.object,
    popupTransitionName: String,
    popupAnimation: PropTypes.any,
    mouseEnterDelay: PropTypes.number.def(0),
    mouseLeaveDelay: PropTypes.number.def(0.1),
    zIndex: Number,
    focusDelay: PropTypes.number.def(0),
    blurDelay: PropTypes.number.def(0.15),
    getPopupContainer: Function,
    getDocument: PropTypes.func.def(returnDocument),
    forceRender: { type: Boolean, default: undefined },
    destroyPopupOnHide: { type: Boolean, default: false },
    mask: { type: Boolean, default: false },
    maskClosable: { type: Boolean, default: true },
    // onPopupAlign: PropTypes.func.def(noop),
    popupAlign: PropTypes.object.def(() => ({})),
    popupVisible: { type: Boolean, default: undefined },
    defaultPopupVisible: { type: Boolean, default: false },
    maskTransitionName: String,
    maskAnimation: String,
    stretch: String,
    alignPoint: { type: Boolean, default: undefined },
    autoDestroy: { type: Boolean, default: false },
    mobile: Object,
    getTriggerDOMNode: Function,
});
