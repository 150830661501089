import PropTypes from '../_util/vue-types';
const TransBtn = (props, { slots }) => {
    var _a;
    const { class: className, customizeIcon, customizeIconProps, onMousedown, onClick } = props;
    let icon;
    if (typeof customizeIcon === 'function') {
        icon = customizeIcon(customizeIconProps);
    }
    else {
        icon = customizeIcon;
    }
    return (<span class={className} onMousedown={event => {
            event.preventDefault();
            if (onMousedown) {
                onMousedown(event);
            }
        }} style={{
            userSelect: 'none',
            WebkitUserSelect: 'none',
        }} unselectable="on" onClick={onClick} aria-hidden>
      {icon !== undefined ? (icon) : (<span class={className.split(/\s+/).map((cls) => `${cls}-icon`)}>
          {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
        </span>)}
    </span>);
};
TransBtn.inheritAttrs = false;
TransBtn.displayName = 'TransBtn';
TransBtn.props = {
    class: String,
    customizeIcon: PropTypes.any,
    customizeIconProps: PropTypes.any,
    onMousedown: Function,
    onClick: Function,
};
export default TransBtn;
