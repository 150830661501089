export default function getRanges({ prefixCls, components = {}, needConfirmButton, onNow, onOk, okDisabled, showNow, locale, }) {
    let presetNode;
    let okNode;
    if (needConfirmButton) {
        const Button = (components.button || 'button');
        if (onNow && showNow !== false) {
            presetNode = (<li class={`${prefixCls}-now`}>
          <a class={`${prefixCls}-now-btn`} onClick={onNow}>
            {locale.now}
          </a>
        </li>);
        }
        okNode = needConfirmButton && (<li class={`${prefixCls}-ok`}>
        <Button disabled={okDisabled} onClick={onOk}>
          {locale.ok}
        </Button>
      </li>);
    }
    if (!presetNode && !okNode) {
        return null;
    }
    return (<ul class={`${prefixCls}-ranges`}>
      {presetNode}
      {okNode}
    </ul>);
}
