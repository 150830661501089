var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { shallowRef, computed, defineComponent } from 'vue';
import { useNotification as useVcNotification } from '../vc-notification';
import CloseOutlined from '@ant-design/icons-vue';
import useStyle from './style';
import { PureContent } from './PurePanel';
import { getMotion } from '../vc-trigger/utils/motionUtil';
import { wrapPromiseFn } from '../_util/util';
import useConfigInject from '../config-provider/hooks/useConfigInject';
import classNames from '../_util/classNames';
const DEFAULT_OFFSET = 8;
const DEFAULT_DURATION = 3;
const Holder = defineComponent({
    name: 'Holder',
    inheritAttrs: false,
    props: [
        'top',
        'prefixCls',
        'getContainer',
        'maxCount',
        'duration',
        'rtl',
        'transitionName',
        'onAllRemoved',
    ],
    setup(props, { expose }) {
        var _a;
        const { getPrefixCls, getPopupContainer } = useConfigInject('message', props);
        const prefixCls = computed(() => getPrefixCls('message', props.prefixCls));
        const [, hashId] = useStyle(prefixCls);
        // =============================== Style ===============================
        const getStyles = () => {
            var _a;
            const top = (_a = props.top) !== null && _a !== void 0 ? _a : DEFAULT_OFFSET;
            return {
                left: '50%',
                transform: 'translateX(-50%)',
                top: typeof top === 'number' ? `${top}px` : top,
            };
        };
        const getClassName = () => classNames(hashId.value, props.rtl ? `${prefixCls.value}-rtl` : '');
        // ============================== Motion ===============================
        const getNotificationMotion = () => {
            var _a;
            return getMotion({
                prefixCls: prefixCls.value,
                animation: (_a = props.animation) !== null && _a !== void 0 ? _a : `move-up`,
                transitionName: props.transitionName,
            });
        };
        // ============================ Close Icon =============================
        const mergedCloseIcon = (<span class={`${prefixCls.value}-close-x`}>
        <CloseOutlined class={`${prefixCls.value}-close-icon`}/>
      </span>);
        // ============================== Origin ===============================
        const [api, holder] = useVcNotification({
            //@ts-ignore
            getStyles,
            prefixCls: prefixCls.value,
            getClassName,
            motion: getNotificationMotion,
            closable: false,
            closeIcon: mergedCloseIcon,
            duration: (_a = props.duration) !== null && _a !== void 0 ? _a : DEFAULT_DURATION,
            getContainer: () => { var _a, _b; return ((_a = props.staticGetContainer) === null || _a === void 0 ? void 0 : _a.call(props)) || ((_b = getPopupContainer.value) === null || _b === void 0 ? void 0 : _b.call(getPopupContainer)) || document.body; },
            maxCount: props.maxCount,
            onAllRemoved: props.onAllRemoved,
        });
        // ================================ Ref ================================
        expose(Object.assign(Object.assign({}, api), { prefixCls,
            hashId }));
        return holder;
    },
});
// ==============================================================================
// ==                                   Hook                                   ==
// ==============================================================================
let keyIndex = 0;
export function useInternalMessage(messageConfig) {
    const holderRef = shallowRef(null);
    const holderKey = Symbol('messageHolderKey');
    // ================================ API ================================
    // Wrap with notification content
    // >>> close
    const close = (key) => {
        var _a;
        (_a = holderRef.value) === null || _a === void 0 ? void 0 : _a.close(key);
    };
    // >>> Open
    const open = (config) => {
        if (!holderRef.value) {
            const fakeResult = () => { };
            fakeResult.then = () => { };
            return fakeResult;
        }
        const { open: originOpen, prefixCls, hashId } = holderRef.value;
        const noticePrefixCls = `${prefixCls}-notice`;
        const { content, icon, type, key, class: className, onClose } = config, restConfig = __rest(config, ["content", "icon", "type", "key", "class", "onClose"]);
        let mergedKey = key;
        if (mergedKey === undefined || mergedKey === null) {
            keyIndex += 1;
            mergedKey = `antd-message-${keyIndex}`;
        }
        return wrapPromiseFn(resolve => {
            originOpen(Object.assign(Object.assign({}, restConfig), { key: mergedKey, content: () => (<PureContent prefixCls={prefixCls} type={type} icon={typeof icon === 'function' ? icon() : icon}>
            {typeof content === 'function' ? content() : content}
          </PureContent>), placement: 'top', 
                // @ts-ignore
                class: classNames(type && `${noticePrefixCls}-${type}`, hashId, className), onClose: () => {
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                    resolve();
                } }));
            // Return close function
            return () => {
                close(mergedKey);
            };
        });
    };
    // >>> destroy
    const destroy = (key) => {
        var _a;
        if (key !== undefined) {
            close(key);
        }
        else {
            (_a = holderRef.value) === null || _a === void 0 ? void 0 : _a.destroy();
        }
    };
    const wrapAPI = {
        open,
        destroy,
    };
    const keys = ['info', 'success', 'warning', 'error', 'loading'];
    keys.forEach(type => {
        const typeOpen = (jointContent, duration, onClose) => {
            let config;
            if (jointContent && typeof jointContent === 'object' && 'content' in jointContent) {
                config = jointContent;
            }
            else {
                config = {
                    content: jointContent,
                };
            }
            // Params
            let mergedDuration;
            let mergedOnClose;
            if (typeof duration === 'function') {
                mergedOnClose = duration;
            }
            else {
                mergedDuration = duration;
                mergedOnClose = onClose;
            }
            const mergedConfig = Object.assign(Object.assign({ onClose: mergedOnClose, duration: mergedDuration }, config), { type });
            return open(mergedConfig);
        };
        wrapAPI[type] = typeOpen;
    });
    // ============================== Return ===============================
    return [wrapAPI, () => <Holder key={holderKey} {...messageConfig} ref={holderRef}/>];
}
export default function useMessage(messageConfig) {
    return useInternalMessage(messageConfig);
}
