var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { watch, computed, reactive, defineComponent, watchEffect } from 'vue';
import defaultRenderEmpty from './renderEmpty';
import LocaleProvider, { ANT_MARK } from '../locale-provider';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import message from '../message';
import notification from '../notification';
import { registerTheme } from './cssVariables';
import defaultLocale from '../locale/en_US';
import useStyle from './style';
import useTheme from './hooks/useTheme';
import defaultSeedToken from '../theme/themes/seed';
import { useConfigContextProvider, useConfigContextInject, configProviderProps, useProvideGlobalForm, defaultIconPrefixCls, } from './context';
import { useProviderSize } from './SizeContext';
import { useProviderDisabled } from './DisabledContext';
import { createTheme } from '../_util/cssinjs';
import { DesignTokenProvider } from '../theme/internal';
export const defaultPrefixCls = 'ant';
export { defaultIconPrefixCls };
function getGlobalPrefixCls() {
    return globalConfigForApi.prefixCls || defaultPrefixCls;
}
function getGlobalIconPrefixCls() {
    return globalConfigForApi.iconPrefixCls || defaultIconPrefixCls;
}
const globalConfigBySet = reactive({}); // 权重最大
export const globalConfigForApi = reactive({});
export const configConsumerProps = [
    'getTargetContainer',
    'getPopupContainer',
    'rootPrefixCls',
    'getPrefixCls',
    'renderEmpty',
    'csp',
    'autoInsertSpaceInButton',
    'locale',
    'pageHeader',
];
watchEffect(() => {
    Object.assign(globalConfigForApi, globalConfigBySet);
    globalConfigForApi.prefixCls = getGlobalPrefixCls();
    globalConfigForApi.iconPrefixCls = getGlobalIconPrefixCls();
    globalConfigForApi.getPrefixCls = (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls
            ? `${globalConfigForApi.prefixCls}-${suffixCls}`
            : globalConfigForApi.prefixCls;
    };
    globalConfigForApi.getRootPrefixCls = () => {
        // If Global prefixCls provided, use this
        if (globalConfigForApi.prefixCls) {
            return globalConfigForApi.prefixCls;
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    };
});
let stopWatchEffect;
const setGlobalConfig = (params) => {
    if (stopWatchEffect) {
        stopWatchEffect();
    }
    stopWatchEffect = watchEffect(() => {
        Object.assign(globalConfigBySet, reactive(params));
        Object.assign(globalConfigForApi, reactive(params));
    });
    if (params.theme) {
        registerTheme(getGlobalPrefixCls(), params.theme);
    }
};
export const globalConfig = () => ({
    getPrefixCls: (suffixCls, customizePrefixCls) => {
        if (customizePrefixCls)
            return customizePrefixCls;
        return suffixCls ? `${getGlobalPrefixCls()}-${suffixCls}` : getGlobalPrefixCls();
    },
    getIconPrefixCls: getGlobalIconPrefixCls,
    getRootPrefixCls: () => {
        // If Global prefixCls provided, use this
        if (globalConfigForApi.prefixCls) {
            return globalConfigForApi.prefixCls;
        }
        // Fallback to default prefixCls
        return getGlobalPrefixCls();
    },
});
const ConfigProvider = defineComponent({
    compatConfig: { MODE: 3 },
    name: 'AConfigProvider',
    inheritAttrs: false,
    props: configProviderProps(),
    setup(props, { slots }) {
        const parentContext = useConfigContextInject();
        const getPrefixCls = (suffixCls, customizePrefixCls) => {
            const { prefixCls = 'ant' } = props;
            if (customizePrefixCls)
                return customizePrefixCls;
            const mergedPrefixCls = prefixCls || parentContext.getPrefixCls('');
            return suffixCls ? `${mergedPrefixCls}-${suffixCls}` : mergedPrefixCls;
        };
        const iconPrefixCls = computed(() => props.iconPrefixCls || parentContext.iconPrefixCls.value || defaultIconPrefixCls);
        const shouldWrapSSR = computed(() => iconPrefixCls.value !== parentContext.iconPrefixCls.value);
        const csp = computed(() => { var _a; return props.csp || ((_a = parentContext.csp) === null || _a === void 0 ? void 0 : _a.value); });
        const wrapSSR = useStyle(iconPrefixCls);
        const mergedTheme = useTheme(computed(() => props.theme), computed(() => { var _a; return (_a = parentContext.theme) === null || _a === void 0 ? void 0 : _a.value; }));
        const renderEmptyComponent = (name) => {
            const renderEmpty = (props.renderEmpty ||
                slots.renderEmpty ||
                parentContext.renderEmpty ||
                defaultRenderEmpty);
            return renderEmpty(name);
        };
        const autoInsertSpaceInButton = computed(() => { var _a, _b; return (_a = props.autoInsertSpaceInButton) !== null && _a !== void 0 ? _a : (_b = parentContext.autoInsertSpaceInButton) === null || _b === void 0 ? void 0 : _b.value; });
        const locale = computed(() => { var _a; return props.locale || ((_a = parentContext.locale) === null || _a === void 0 ? void 0 : _a.value); });
        watch(locale, () => {
            globalConfigBySet.locale = locale.value;
        }, { immediate: true });
        const direction = computed(() => { var _a; return props.direction || ((_a = parentContext.direction) === null || _a === void 0 ? void 0 : _a.value); });
        const space = computed(() => { var _a, _b; return (_a = props.space) !== null && _a !== void 0 ? _a : (_b = parentContext.space) === null || _b === void 0 ? void 0 : _b.value; });
        const virtual = computed(() => { var _a, _b; return (_a = props.virtual) !== null && _a !== void 0 ? _a : (_b = parentContext.virtual) === null || _b === void 0 ? void 0 : _b.value; });
        const dropdownMatchSelectWidth = computed(() => { var _a, _b; return (_a = props.dropdownMatchSelectWidth) !== null && _a !== void 0 ? _a : (_b = parentContext.dropdownMatchSelectWidth) === null || _b === void 0 ? void 0 : _b.value; });
        const getTargetContainer = computed(() => {
            var _a;
            return props.getTargetContainer !== undefined
                ? props.getTargetContainer
                : (_a = parentContext.getTargetContainer) === null || _a === void 0 ? void 0 : _a.value;
        });
        const getPopupContainer = computed(() => {
            var _a;
            return props.getPopupContainer !== undefined
                ? props.getPopupContainer
                : (_a = parentContext.getPopupContainer) === null || _a === void 0 ? void 0 : _a.value;
        });
        const pageHeader = computed(() => { var _a; return props.pageHeader !== undefined ? props.pageHeader : (_a = parentContext.pageHeader) === null || _a === void 0 ? void 0 : _a.value; });
        const input = computed(() => { var _a; return props.input !== undefined ? props.input : (_a = parentContext.input) === null || _a === void 0 ? void 0 : _a.value; });
        const pagination = computed(() => { var _a; return props.pagination !== undefined ? props.pagination : (_a = parentContext.pagination) === null || _a === void 0 ? void 0 : _a.value; });
        const form = computed(() => { var _a; return props.form !== undefined ? props.form : (_a = parentContext.form) === null || _a === void 0 ? void 0 : _a.value; });
        const select = computed(() => { var _a; return props.select !== undefined ? props.select : (_a = parentContext.select) === null || _a === void 0 ? void 0 : _a.value; });
        const componentSize = computed(() => props.componentSize);
        const componentDisabled = computed(() => props.componentDisabled);
        const configProvider = {
            csp,
            autoInsertSpaceInButton,
            locale,
            direction,
            space,
            virtual,
            dropdownMatchSelectWidth,
            getPrefixCls,
            iconPrefixCls,
            theme: computed(() => {
                var _a, _b;
                return (_a = mergedTheme.value) !== null && _a !== void 0 ? _a : (_b = parentContext.theme) === null || _b === void 0 ? void 0 : _b.value;
            }),
            renderEmpty: renderEmptyComponent,
            getTargetContainer,
            getPopupContainer,
            pageHeader,
            input,
            pagination,
            form,
            select,
            componentSize,
            componentDisabled,
            transformCellText: computed(() => props.transformCellText),
        };
        // ================================ Dynamic theme ================================
        const memoTheme = computed(() => {
            const _a = mergedTheme.value || {}, { algorithm, token } = _a, rest = __rest(_a, ["algorithm", "token"]);
            const themeObj = algorithm && (!Array.isArray(algorithm) || algorithm.length > 0)
                ? createTheme(algorithm)
                : undefined;
            return Object.assign(Object.assign({}, rest), { theme: themeObj, token: Object.assign(Object.assign({}, defaultSeedToken), token) });
        });
        const validateMessagesRef = computed(() => {
            var _a, _b;
            // Additional Form provider
            let validateMessages = {};
            if (locale.value) {
                validateMessages =
                    ((_a = locale.value.Form) === null || _a === void 0 ? void 0 : _a.defaultValidateMessages) ||
                        ((_b = defaultLocale.Form) === null || _b === void 0 ? void 0 : _b.defaultValidateMessages) ||
                        {};
            }
            if (props.form && props.form.validateMessages) {
                validateMessages = Object.assign(Object.assign({}, validateMessages), props.form.validateMessages);
            }
            return validateMessages;
        });
        useConfigContextProvider(configProvider);
        useProvideGlobalForm({ validateMessages: validateMessagesRef });
        useProviderSize(componentSize);
        useProviderDisabled(componentDisabled);
        const renderProvider = (legacyLocale) => {
            var _a, _b;
            let childNode = shouldWrapSSR.value ? wrapSSR((_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)) : (_b = slots.default) === null || _b === void 0 ? void 0 : _b.call(slots);
            if (props.theme)
                childNode = <DesignTokenProvider value={memoTheme.value}>{childNode}</DesignTokenProvider>;
            return (<LocaleProvider locale={locale.value || legacyLocale} ANT_MARK__={ANT_MARK}>
          {childNode}
        </LocaleProvider>);
        };
        watchEffect(() => {
            if (direction.value) {
                message.config({
                    rtl: direction.value === 'rtl',
                });
                notification.config({
                    rtl: direction.value === 'rtl',
                });
            }
        });
        return () => (<LocaleReceiver children={(_, __, legacyLocale) => renderProvider(legacyLocale)}/>);
    },
});
ConfigProvider.config = setGlobalConfig;
ConfigProvider.install = function (app) {
    app.component(ConfigProvider.name, ConfigProvider);
};
export default ConfigProvider;
