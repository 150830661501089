import { computed, defineComponent } from 'vue';
import { Circle as VCCircle } from '../vc-progress';
import { getPercentage, getSize, getStrokeColor } from './utils';
import { progressProps } from './props';
import { initDefaultProps } from '../_util/props-util';
import Tooltip from '../tooltip';
import { anyType } from '../_util/type';
export const circleProps = () => (Object.assign(Object.assign({}, progressProps()), { strokeColor: anyType() }));
const CIRCLE_MIN_STROKE_WIDTH = 3;
const getMinPercent = (width) => (CIRCLE_MIN_STROKE_WIDTH / width) * 100;
export default defineComponent({
    compatConfig: { MODE: 3 },
    name: 'Circle',
    inheritAttrs: false,
    props: initDefaultProps(circleProps(), {
        trailColor: null,
    }),
    setup(props, { slots, attrs }) {
        const originWidth = computed(() => { var _a; return (_a = props.width) !== null && _a !== void 0 ? _a : 120; });
        const mergedSize = computed(() => { var _a; return (_a = props.size) !== null && _a !== void 0 ? _a : [originWidth.value, originWidth.value]; });
        const sizeRef = computed(() => getSize(mergedSize.value, 'circle'));
        const gapDeg = computed(() => {
            // Support gapDeg = 0 when type = 'dashboard'
            if (props.gapDegree || props.gapDegree === 0) {
                return props.gapDegree;
            }
            if (props.type === 'dashboard') {
                return 75;
            }
            return undefined;
        });
        const circleStyle = computed(() => {
            return {
                width: `${sizeRef.value.width}px`,
                height: `${sizeRef.value.height}px`,
                fontSize: `${sizeRef.value.width * 0.15 + 6}px`,
            };
        });
        const circleWidth = computed(() => { var _a; return (_a = props.strokeWidth) !== null && _a !== void 0 ? _a : Math.max(getMinPercent(sizeRef.value.width), 6); });
        const gapPos = computed(() => props.gapPosition || (props.type === 'dashboard' && 'bottom') || undefined);
        // using className to style stroke color
        const percent = computed(() => getPercentage(props));
        const isGradient = computed(() => Object.prototype.toString.call(props.strokeColor) === '[object Object]');
        const strokeColor = computed(() => getStrokeColor({ success: props.success, strokeColor: props.strokeColor }));
        const wrapperClassName = computed(() => ({
            [`${props.prefixCls}-inner`]: true,
            [`${props.prefixCls}-circle-gradient`]: isGradient.value,
        }));
        return () => {
            var _a;
            const circleContent = (<VCCircle percent={percent.value} strokeWidth={circleWidth.value} trailWidth={circleWidth.value} strokeColor={strokeColor.value} strokeLinecap={props.strokeLinecap} trailColor={props.trailColor} prefixCls={props.prefixCls} gapDegree={gapDeg.value} gapPosition={gapPos.value}/>);
            return (<div {...attrs} class={[wrapperClassName.value, attrs.class]} style={[attrs.style, circleStyle.value]}>
          {sizeRef.value.width <= 20 ? (<Tooltip v-slots={{ title: slots.default }}>
              <span>{circleContent}</span>
            </Tooltip>) : (<>
              {circleContent}
              {(_a = slots.default) === null || _a === void 0 ? void 0 : _a.call(slots)}
            </>)}
        </div>);
        };
    },
});
